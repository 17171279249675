import { type AxiosError } from 'axios'
import useSWR, { mutate, type SWRResponse } from 'swr'

import {
  ProductReviewControllerApiFactory,
  type ProductReviewServiceReviewV1,
} from '@redteclab/api/clients/bully'

import {
  buildClientSideBullyRequestConfig,
  useBullyApi,
} from '../../../base-api-communication'
import { useGlobalConfigContext } from '../../../global-config'

const getUseProductApiSwrFetchUserReviewKey = (upid: string): string =>
  `useProductApiSwrFetchUserReview_${upid}`

export const useProductApiSwrFetchUserReview = (
  upid: string | null,
): SWRResponse<ProductReviewServiceReviewV1, AxiosError> => {
  const client = useBullyApi(ProductReviewControllerApiFactory)
  const globalConfig = useGlobalConfigContext()

  const fetcher = async (): Promise<ProductReviewServiceReviewV1> => {
    if (!upid) {
      throw new Error('Upid not provided')
    }

    const response = await client.productReviewV1LanguageTenantProductsUpidGet(
      {
        language: globalConfig.language,
        tenant: globalConfig.tenantAndEnv,
        upid,
      },
      buildClientSideBullyRequestConfig(globalConfig),
    )

    return response.data
  }

  return useSWR(
    upid ? getUseProductApiSwrFetchUserReviewKey(upid) : null,
    fetcher,
    {
      revalidateOnFocus: false,
    },
  )
}

export const refetchUseProductApiSwrFetchUserReview = async (
  upid: string,
): Promise<ProductReviewServiceReviewV1 | undefined> => {
  return mutate<ProductReviewServiceReviewV1>(
    getUseProductApiSwrFetchUserReviewKey(upid),
  )
}
