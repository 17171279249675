'use client'

import { type FC } from 'react'

import { ProductReviewsList } from './ProductReviewsList/ProductReviewsList'
import { ProductReviewsNewReviewContainer } from './ProductReviewsNewReviewContainer'
import { ProductReviewsRatingSummary } from './ProductReviewsRatingSummary'
import { ProductReviewsSummaryAndReviewsListProvider } from './ProductReviewsSummaryAndReviewsListContext'
import { type ProductReviewsSummaryAndReviewsListProps } from './ProductReviewsSummaryAndReviewsListProps'

const RATINGS_CRITERIA_CONTAINER_ID = 'ProductReviewsInformationBox'

const ProductReviewsSummaryAndReviewsList: FC<
  ProductReviewsSummaryAndReviewsListProps
> = ({
  id,
  initialReviews,
  isSellerRatingSummary,
  languageSwitcherSlot,
  noBorderOnLastEntry,
  onFetchReviews,
  productUpidForReview,
  reviewsLanguage,
  summary,
  totalReviews,
  truncateLongComments,
  withoutCreateForm,
}) => {
  return (
    <ProductReviewsSummaryAndReviewsListProvider
      id={id}
      initialReviews={initialReviews}
      noBorderOnLastEntry={noBorderOnLastEntry}
      onFetchReviews={onFetchReviews}
      productUpidForReview={productUpidForReview}
      reviewsLanguage={reviewsLanguage}
      summary={summary}
      totalReviews={totalReviews}
      truncateLongComments={truncateLongComments}
    >
      {withoutCreateForm ? (
        <ProductReviewsRatingSummary
          isSellerRatingSummary={isSellerRatingSummary}
          showReviewCriteria={false}
        />
      ) : (
        <div className="flex flex-col gap-7 text-dark-primary-max tablet:flex-row">
          <ProductReviewsRatingSummary
            className="tablet:w-7/12"
            isSellerRatingSummary={isSellerRatingSummary}
            languageSwitcherSlot={languageSwitcherSlot}
            reviewCriteriaContainerId={RATINGS_CRITERIA_CONTAINER_ID}
            showReviewCriteria={totalReviews > 0}
          />
          <ProductReviewsNewReviewContainer className="tablet:w-5/12" />
        </div>
      )}
      <ProductReviewsList
        reviewCriteriaContainerId={RATINGS_CRITERIA_CONTAINER_ID}
        showReviewCriteria={withoutCreateForm ? false : totalReviews > 0}
      />
    </ProductReviewsSummaryAndReviewsListProvider>
  )
}

export { ProductReviewsSummaryAndReviewsList }
