'use client'

import dynamic from 'next/dynamic'

import { Loading } from '../../../loading'

export const LoginRegistrationDynamicUserLoginModalContent = dynamic(
  async () =>
    import('./LoginRegistrationUserLoginModalContent').then(
      (mod) => mod.LoginRegistrationUserLoginModalContent,
    ),
  {
    loading: () => <Loading />,
    ssr: false,
  },
)
