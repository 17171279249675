'use client'

import { clsx } from 'clsx'
import { type FC, type ReactElement, type ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'

import { Button, Link } from 'base-ui'

import {
  type PublicGlobalConfig,
  useGlobalConfigContext,
} from '../../../../../global-config'
import { urlResolverGetFaqReviewsCriteria } from '../../../../../url-handling'
import { useProductReviewsSummaryAndReviewsListContext } from '../ProductReviewsSummaryAndReviewsListContext'

import { ProductReviewsListReviewEntry } from './ProductReviewListReviewEntry'
import { type ProductReviewsListProps } from './ProductReviewsListProps'

const faqLinkChunk = (
  config: PublicGlobalConfig,
  chunk: ReactNode[],
): ReactElement => {
  return <Link href={urlResolverGetFaqReviewsCriteria(config)}>{chunk}</Link>
}

const ProductReviewsList: FC<ProductReviewsListProps> = ({
  reviewCriteriaContainerId,
  showReviewCriteria,
}) => {
  const {
    hasMore,
    isLoading,
    noBorderOnLastEntry,
    requestMore,
    reviewsToDisplay,
    truncateLongComments,
  } = useProductReviewsSummaryAndReviewsListContext()

  const config = useGlobalConfigContext()

  return (
    <>
      <ul className="text-dark-primary-max">
        {reviewsToDisplay.map((review) => (
          <li
            className={clsx(
              'border-b border-b-dark-primary-min py-5',
              !hasMore && noBorderOnLastEntry && 'last:border-b-0',
            )}
            key={review.id}
          >
            <ProductReviewsListReviewEntry
              review={review}
              truncateLongComments={truncateLongComments}
            />
          </li>
        ))}
      </ul>
      {hasMore ? (
        <div className="flex items-center justify-center pt-7">
          <Button
            className="w-full tablet:w-auto"
            disabled={isLoading}
            onClick={requestMore}
            type="button"
          >
            <FormattedMessage id="general.label.showMore" />
          </Button>
        </div>
      ) : null}
      {showReviewCriteria ? (
        <div
          className="mb-5 mt-3.5 rounded bg-light-primary-medium p-5"
          id={reviewCriteriaContainerId}
        >
          <h3 className="mb-2 font-bold">
            <FormattedMessage id="general.label.productReviews.info.title" />
          </h3>
          <div>
            <p className="break-words">
              <FormattedMessage
                id="general.label.productReviews.info.content"
                values={{ faqLink: (chunk) => faqLinkChunk(config, chunk) }}
              />
            </p>
          </div>
        </div>
      ) : null}
    </>
  )
}

export { ProductReviewsList }
