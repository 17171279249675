'use client'

import { type FC } from 'react'
import { FormattedMessage, FormattedNumber } from 'react-intl'

import { Link } from 'base-ui'

import { useGlobalConfigContext } from '../../../../../global-config'
import {
  urlResolverGetFaqHowReviewScoreCalculated,
  urlResolverGetFaqSellerEvaluations,
} from '../../../../../url-handling'
import { useProductReviewsSummaryAndReviewsListContext } from '../ProductReviewsSummaryAndReviewsListContext'

import { ProductReviewsRatingOverviewStarReviewsCountList } from './ProductReviewsRatingOverviewStarReviewsCountList'
import { type ProductReviewsRatingSummaryProps } from './ProductReviewsRatingSummaryProps'

const ProductReviewsRatingSummary: FC<ProductReviewsRatingSummaryProps> = ({
  className,
  isSellerRatingSummary,
  languageSwitcherSlot,
  reviewCriteriaContainerId,
  showReviewCriteria,
}) => {
  const config = useGlobalConfigContext()
  const {
    summary: { averageRating },
  } = useProductReviewsSummaryAndReviewsListContext()

  return (
    <div className={className}>
      <div className="rounded bg-light-primary-medium p-5">
        <div className="flex">
          <div className="flex flex-1 flex-col items-center justify-center text-s font-medium">
            <span className="mb-2.5 text-4xl">
              <FormattedNumber
                maximumFractionDigits={1}
                value={averageRating}
              />
            </span>
            <span>
              {averageRating === 0 ? (
                <FormattedMessage id="general.label.Ratings" />
              ) : (
                <FormattedMessage id="general.label.product.ratingsOutOfFiveStars" />
              )}
            </span>
          </div>
          <ProductReviewsRatingOverviewStarReviewsCountList />
        </div>
        <div className="pt-3.5 text-center">
          {isSellerRatingSummary ? (
            <Link href={urlResolverGetFaqSellerEvaluations(config)}>
              <FormattedMessage id="seller.modal.ratings.faq" />
            </Link>
          ) : (
            <Link href={urlResolverGetFaqHowReviewScoreCalculated(config)}>
              <FormattedMessage id="general.label.productReviews.faqProductNote" />
            </Link>
          )}
        </div>
      </div>
      {showReviewCriteria && reviewCriteriaContainerId ? (
        <div className="pt-3.5 text-center">
          <Link href={`#${reviewCriteriaContainerId}`}>
            <FormattedMessage id="general.label.productReviews.faqProductReviews" />
          </Link>
        </div>
      ) : null}
      {languageSwitcherSlot ?? null}
    </div>
  )
}

export { ProductReviewsRatingSummary }
