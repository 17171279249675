'use client'

import { type FC, type ReactElement, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { Button, Link } from 'base-ui'

import { useGlobalConfigContext } from '../../../../../global-config'
import { LoginRegistrationUserLoginModal } from '../../../../../login-registration'
import { TENANT } from '../../../../../tenant'
import { urlResolverGetFaqProductReviews } from '../../../../../url-handling'
import { useUserApiSwrSessionV1Customer } from '../../../../../user-data'

import { type ProductReviewsNewReviewDialogContainerProps } from './ProductReviewsNewReviewDialogContainerProps'
import { ProductReviewsNewReviewFormDialog } from './ProductReviewsNewReviewFormDialog'

const ProductReviewsNewReviewContainer: FC<
  ProductReviewsNewReviewDialogContainerProps
> = ({ className }) => {
  const globalConfig = useGlobalConfigContext()
  const { data, isLoading } = useUserApiSwrSessionV1Customer()

  const [showLoginForm, setShowLoginForm] = useState(false)
  const [showReviewForm, setShowReviewForm] = useState(false)
  const [loginFormClosed, setLoginFormClosed] = useState(false)

  const handleButtonClick = (): void => {
    if (isLoading) {
      return
    }

    if (data?.customerNumber) {
      setShowReviewForm(true)
    } else {
      setLoginFormClosed(false)
      setShowLoginForm(true)
    }
  }

  useEffect(() => {
    if (loginFormClosed && data?.customerNumber) {
      setShowReviewForm(true)
    }
  }, [data?.customerNumber, loginFormClosed])

  const voucherPercentage = (): ReactElement => (
    <strong>
      <FormattedMessage id="general.label.voucherReview" />
    </strong>
  )

  return (
    <div className={className}>
      <Button
        className="mb-3.5 w-full"
        data-qa-id={
          data?.customerNumber
            ? 'form-ProductDescription-show-rating-forms'
            : 'form-ProductDescription-show-login-modal'
        }
        onClick={handleButtonClick}
        type="button"
      >
        {isLoading ? (
          'Loading...'
        ) : (
          <FormattedMessage id="userReview.form.writeRating" />
        )}
      </Button>
      <p>
        <FormattedMessage
          id="getVoucherBox.label.information_ho"
          values={{
            voucherValue: voucherPercentage(),
          }}
        />
      </p>
      {globalConfig.tenant === TENANT.COM && (
        <p className="mt-3">
          <Link
            data-qa-id="more-info-about-premium-reviews"
            href={urlResolverGetFaqProductReviews(globalConfig)}
          >
            <FormattedMessage id="getVoucherBox.label.faqLink" />
          </Link>
        </p>
      )}
      <LoginRegistrationUserLoginModal
        afterLoggedIn={(): void => {
          setShowLoginForm(false)
          setLoginFormClosed(true)
        }}
        onClose={(): void => {
          setShowLoginForm(false)
          setLoginFormClosed(true)
        }}
        open={showLoginForm}
      />
      <ProductReviewsNewReviewFormDialog
        className="w-full"
        isOpen={showReviewForm}
        onClose={(): void => {
          setShowReviewForm(false)
        }}
      />
    </div>
  )
}

export { ProductReviewsNewReviewContainer }
