'use client'

import { clsx } from 'clsx'
import { type FC, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { Link } from 'base-ui'

import { type ProductReviewListReviewEntryTruncatedMessageProps } from './ProductReviewListReviewEntryTruncatedMessageProps'

const SHORT_COMMENT_LENGTH = 150

const ProductReviewListReviewEntryTruncatedMessage: FC<
  ProductReviewListReviewEntryTruncatedMessageProps
> = ({ className, initiallyTruncated, message }) => {
  const [showTruncated, setShowTruncated] = useState(
    initiallyTruncated && (message?.length ?? 0) > SHORT_COMMENT_LENGTH,
  )

  if (!message) {
    return null
  }

  return (
    <p className={clsx('text-s', className)}>
      {showTruncated ? (
        <>
          {`${message.slice(0, SHORT_COMMENT_LENGTH)}...`}
          <Link<'button'>
            className="font-bold"
            onClick={(): void => {
              setShowTruncated(false)
            }}
            slots={{ root: 'button' }}
          >
            <FormattedMessage id="general.label.readOn" />
          </Link>
        </>
      ) : (
        message
      )}
    </p>
  )
}

export { ProductReviewListReviewEntryTruncatedMessage }
