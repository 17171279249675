import { type FC, type ReactElement, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { Dialog, DialogContent, DialogTitle, Notification } from 'base-ui'

import { Loading } from '../../../../../loading'
import {
  refetchUseProductApiSwrFetchUserReview,
  useProductApiSwrFetchUserReview,
} from '../../../../api/swr/useProductApiSwrFetchUserReview'
import { useProductReviewsSummaryAndReviewsListContext } from '../ProductReviewsSummaryAndReviewsListContext'

import { DynamicProductReviewsNewReviewFormContainer } from './ProductReviewsNewReviewFormContainer'

const ProductReviewsNewReviewFormDialog: FC<{
  className: string
  isOpen: boolean
  onClose: () => void
}> = ({ className, isOpen, onClose }) => {
  /**
   * We want to trigger the fetching only if and after the user opens the modal.
   * There is no need to check if the user already added a review for the product
   * if they will not try to add a review
   */
  const [activateSwr, setActivateSwr] = useState(false)

  const { productUpidForReview } =
    useProductReviewsSummaryAndReviewsListContext()

  const { data, isLoading } = useProductApiSwrFetchUserReview(
    activateSwr ? productUpidForReview : null,
  )
  const intl = useIntl()
  const [refetchOnClose, setRefetchOnClose] = useState(false)

  const handleAfterReviewCreated = (): void => {
    setRefetchOnClose(true)
  }

  const handleOnClose = (): void => {
    if (refetchOnClose) {
      setActivateSwr(false)
      setRefetchOnClose(false)
      void refetchUseProductApiSwrFetchUserReview(productUpidForReview)
    }
    onClose()
  }

  useEffect(() => {
    // only activate (once) after the user opens the modal
    if (isOpen && !isLoading && !data) {
      setActivateSwr(true)
    }
  }, [data, isLoading, isOpen])

  const content = (hasReview: boolean): ReactElement =>
    hasReview ? (
      <Notification data-qa-id="product-review-form--already-created">
        <FormattedMessage id="productRating.notification.message.hasAlreadyReview" />
      </Notification>
    ) : (
      <DynamicProductReviewsNewReviewFormContainer
        onReviewCreated={handleAfterReviewCreated}
      />
    )

  return (
    <Dialog
      onClose={handleOnClose}
      open={isOpen}
      slotProps={{
        dialog: {
          className,
        },
      }}
    >
      <DialogTitle
        closeText={intl.formatMessage({ id: 'general.link.label.close' })}
        onClose={handleOnClose}
      >
        <FormattedMessage id="userReview.form.writeRating" />
      </DialogTitle>
      <DialogContent>
        {isLoading ? <Loading /> : content(Boolean(data?.reviewId))}
      </DialogContent>
    </Dialog>
  )
}

export { ProductReviewsNewReviewFormDialog }
