import dynamic from 'next/dynamic'

import { Loading } from '../../../../../../loading'

export const DynamicProductReviewsNewReviewFormContainer = dynamic(
  async () =>
    import('./ProductReviewsNewReviewFormContainer').then(
      (mod) => mod.ProductReviewsNewReviewFormContainer,
    ),
  {
    loading: () => <Loading />,
    ssr: false,
  },
)
