import { type FC } from 'react'
import { FormattedMessage } from 'react-intl'

import { Dialog, DialogContent, DialogTitle } from 'base-ui'

import { LoginRegistrationDynamicUserLoginModalContent } from './LoginRegistrationDynamicUserLoginModalContent'

type LoginRegistrationUserLoginModalProps = {
  afterLoggedIn?: (afterLoggedInSideEffectsPromise?: Promise<unknown>) => void
  onClose: () => void
  open: boolean
}

export const LoginRegistrationUserLoginModal: FC<
  LoginRegistrationUserLoginModalProps
> = ({ afterLoggedIn, onClose, open }) => {
  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle onClose={onClose}>
        <FormattedMessage id="general.link.label.login" />
      </DialogTitle>
      <DialogContent className="tablet:max-w-lg">
        <LoginRegistrationDynamicUserLoginModalContent
          afterLoggedIn={afterLoggedIn}
        />
      </DialogContent>
    </Dialog>
  )
}
