'use client'

import { type FC } from 'react'
import { FormattedNumber } from 'react-intl'

import { Rating } from 'base-ui'

import { useProductReviewsSummaryAndReviewsListContext } from '../ProductReviewsSummaryAndReviewsListContext'

const MAX_STARS_RATING = 5

const ProductReviewsRatingOverviewStarReviewsCountList: FC = () => {
  const { summary } = useProductReviewsSummaryAndReviewsListContext()

  const starReviewValues = [
    summary.numberOfFiveStarReviews,
    summary.numberOfFourStarReviews,
    summary.numberOfThreeStarReviews,
    summary.numberOfTwoStarReviews,
    summary.numberOfOneStarReviews,
  ]

  return (
    <ul>
      {starReviewValues.map((rating, index) => (
        // It's not possible to have an id on the ratings values. Using index as key is safe here
        // eslint-disable-next-line react/no-array-index-key
        <li className="mb-1.5 flex gap-2" key={index}>
          <Rating
            className="text-2xl"
            readOnly
            value={MAX_STARS_RATING - index}
          />
          <FormattedNumber value={rating} />
        </li>
      ))}
    </ul>
  )
}

export { ProductReviewsRatingOverviewStarReviewsCountList }
