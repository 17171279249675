import { clsx } from 'clsx'
import { type FC, type ReactElement } from 'react'
import { FormattedDate, FormattedMessage } from 'react-intl'

import { Rating } from 'base-ui'

import { TosSuperscriptHowProductRatingCalculated } from '../../../../../tos-superscripts'

import { type ProductReviewListReviewEntryProps } from './ProductReviewListReviewEntryProps'
import { ProductReviewListReviewEntryTruncatedMessage } from './ProductReviewListReviewEntryTruncatedMessage'

const SHOP_APOTHEKE_SELLER_NAME = 'SHOP APOTHEKE'
const SELLER_REPLY_TYPE_OPERATOR = 'OPERATOR'

const renderDate = (date?: string): ReactElement => (
  <FormattedDate day="2-digit" month="2-digit" value={date} year="numeric" />
)

const ProductReviewsListReviewEntry: FC<ProductReviewListReviewEntryProps> = ({
  review,
  truncateLongComments = false,
}) => {
  return (
    <article>
      <h3 className="mb-2 flex flex-col tablet:flex-row tablet:items-center">
        <div>
          <Rating
            className="mb-2 text-3xl tablet:mb-0"
            readOnly
            value={review.rating}
          />
          <TosSuperscriptHowProductRatingCalculated className="align-text-top text-dark-primary-low" />
        </div>
        <span className="text-m font-medium tablet:ml-2.5">{review.title}</span>
      </h3>
      <h4 className="mb-2.5 text-xs font-medium">
        <FormattedMessage id="userReview.label.byCustomer" />{' '}
        {(review.customerName?.length ?? 0) > 0 ? (
          review.customerName
        ) : (
          <FormattedMessage id="userReview.label.fromOneCostumer" />
        )}{' '}
        <FormattedMessage id="userReview.label.onDate" />{' '}
        {renderDate(review.submissionDate)}
      </h4>
      <ProductReviewListReviewEntryTruncatedMessage
        initiallyTruncated={truncateLongComments}
        message={review.message}
      />
      {review.reply ? (
        <div
          className={clsx(
            'mt-2 rounded bg-light-primary-medium py-3.5 pl-5 pr-3.5',
            review.reply.type === SELLER_REPLY_TYPE_OPERATOR &&
              'bg-light-brand-medium',
          )}
        >
          <small className="font-bold">
            <FormattedMessage
              id="sellerReview.label.replyedBy.replyedOn"
              values={{
                date: renderDate(review.reply.replyDate),
                seller:
                  review.reply.type === SELLER_REPLY_TYPE_OPERATOR
                    ? SHOP_APOTHEKE_SELLER_NAME
                    : review.reply.responder,
              }}
            />
          </small>
          <ProductReviewListReviewEntryTruncatedMessage
            className="mt-3.5"
            initiallyTruncated={truncateLongComments}
            message={review.reply.comment}
          />
        </div>
      ) : null}
    </article>
  )
}

export { ProductReviewsListReviewEntry }
