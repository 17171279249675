'use client'

import {
  createContext,
  type FC,
  type PropsWithChildren,
  useContext,
  useMemo,
} from 'react'
import useSWRInfinite from 'swr/infinite'

export interface ProductReviewsRatingSummaryValues {
  averageRating: number
  numberOfFiveStarReviews: number
  numberOfFourStarReviews: number
  numberOfOneStarReviews: number
  numberOfThreeStarReviews: number
  numberOfTwoStarReviews: number
}

export interface ProductReviewsCustomerReview {
  customerName?: string
  id: string
  message: string
  rating: number
  reply?: {
    comment?: string
    replyDate?: string
    responder?: string
    type?: string
  }
  submissionDate?: string
  title: string
}

type ProductReviewsSummaryAndReviewsListContextValue = {
  hasMore: boolean
  isLoading: boolean
  noBorderOnLastEntry: boolean
  productUpidForReview: string
  requestMore: () => void
  reviews: ProductReviewsCustomerReview[]
  reviewsToDisplay: ProductReviewsCustomerReview[]
  summary: ProductReviewsRatingSummaryValues
  truncateLongComments: boolean
}

const NUMBER_OF_REVIEWS_PER_PAGE = 5

const ProductReviewsSummaryAndReviewsListContext = createContext<
  ProductReviewsSummaryAndReviewsListContextValue | undefined
>(undefined)

const getKey = (
  pageIndex: number,
  id: string,
  reviewsCurrentLanguage?: string,
): string | null => {
  return `${id}${pageIndex + 1}${reviewsCurrentLanguage}`
}

export const ProductReviewsSummaryAndReviewsListProvider: FC<
  PropsWithChildren<{
    id: string
    initialReviews?: ProductReviewsCustomerReview[]
    noBorderOnLastEntry?: boolean
    onFetchReviews: (
      page: number,
      pageSize: number,
    ) => Promise<ProductReviewsCustomerReview[]>
    productUpidForReview: string
    reviewsLanguage?: string
    summary: ProductReviewsRatingSummaryValues
    totalReviews: number
    truncateLongComments?: boolean
  }>
> = ({
  children,
  id,
  initialReviews,
  noBorderOnLastEntry = false,
  onFetchReviews,
  productUpidForReview,
  reviewsLanguage,
  summary,
  totalReviews,
  truncateLongComments = false,
}) => {
  const { data, isLoading, setSize, size } = useSWRInfinite<
    ProductReviewsCustomerReview[],
    Error
  >(
    (pageIndex) => getKey(pageIndex, id, reviewsLanguage),
    async (pageId: string) => {
      return onFetchReviews(
        Number.parseInt(pageId.replace(id, ''), 10),
        NUMBER_OF_REVIEWS_PER_PAGE,
      )
    },
    {
      fallbackData: initialReviews ? [initialReviews] : undefined,
      revalidateFirstPage: false,
      revalidateOnFocus: false,
      revalidateOnMount: !initialReviews,
      revalidateOnReconnect: false,
    },
  )

  const reviews = (data ?? []).flat()

  const contextValue = useMemo(
    (): ProductReviewsSummaryAndReviewsListContextValue => ({
      hasMore: reviews.length < totalReviews && reviews.length > 0,
      isLoading,
      noBorderOnLastEntry,
      productUpidForReview,
      requestMore: (): void => {
        if (!isLoading) {
          void setSize(size + 1)
        }
      },
      reviews,
      reviewsToDisplay: reviews,
      summary,
      truncateLongComments,
    }),
    [
      reviews,
      totalReviews,
      isLoading,
      noBorderOnLastEntry,
      productUpidForReview,
      summary,
      truncateLongComments,
      setSize,
      size,
    ],
  )

  return (
    <ProductReviewsSummaryAndReviewsListContext.Provider value={contextValue}>
      {children}
    </ProductReviewsSummaryAndReviewsListContext.Provider>
  )
}

export const useProductReviewsSummaryAndReviewsListContext =
  (): ProductReviewsSummaryAndReviewsListContextValue => {
    const contextValue = useContext(ProductReviewsSummaryAndReviewsListContext)

    if (contextValue === undefined) {
      throw new Error(
        'useProductReviewsSummaryAndListContext must be used within a ProductReviewsSummaryAndListContext',
      )
    }

    return contextValue
  }
